<script>
import Switches from "vue-switches";
import { required } from "vuelidate/lib/validators";

import {
	integrationMethods
} from "@/state/helpers";

/**
 * New Campaign  component
 */

export default {
	components: {
		Switches
	},
	data() {
		return {
			submitted:false,
			magentoEnabled:false,
			customEnabled: false,
			integrationModal:false,
			integrationTitle: '',
			integrations:[],
			integration:{
				data : {}	
			}
		};
	},
	validations: {
		integration: {
			data: {
				url: { required },
				token: { required },
			}
		},
	},
	watch: {
		//magentoEnabled(){
		//	this.onIntegrationChanged('magento');
		//}
	},
    methods: {
		...integrationMethods,

		loadIntegrations(){
			if(this.project){
				let loader = this.$loading.show();
				const params = {
					q : `where[project]=${this.project._id}`
				}

				this.getIntegrations(params).then((res)=>{
					this.integrations = res.data;
					
					this.magentoEnabled = this.integrations.filter((i)=> i.type == 'magento').length > 0;
					this.customEnabled = this.integrations.filter((i)=> i.type == 'custom').length > 0;

				}).catch(()=>{
					this.$notify({ type: 'error', text: 'Se produjo un error al obtener las integraciones',title:  'Integración' });
				}).finally(()=>{
					loader.hide();
				})
			}
		},

		onIntegrationChanged(type){

			if(type == 'magento' && this.magentoEnabled){
				this.onIntegrationClicked('magento')
			}else{
				if(this.integrations.filter((i)=> i.type == 'magento').length > 0){

						let loader = this.$loading.show();

						this.integration = this.integrations.filter((i)=> i.type == 'magento')[0];

						this.deleteIntegration(this.integration._id).then(()=>{
						this.$notify({ type: 'success', text: 'Se ha eliminado la integración correctamente',title:  'Integración' });
						this.integrationModal = false;
						this.loadIntegrations();
					}).catch(()=>{
						this.$notify({ type: 'error', text: 'Se produjo un error al eliminar la integración',title:  'Integración' });
					}).finally(()=>{
						loader.hide();
					})
				}
				
			}

			if(!this.magentoEnabled)
				this.integrationModal = false;
		},

		onIntegrationClicked(type){

			if(type == 'magento'){
				this.integration.type = 'magento';
				this.magentoEnabled = true;
				this.integrationTitle = 'Magento';
				this.integrationModal = true;

				this.integration =  this.integrations.filter((i)=> i.type == 'magento').length > 0 ? this.integrations.filter((i)=> i.type == 'magento') [0] : this.integration;
			}
		},

		onConfirmIntegrationClicked(){
			this.submitted = true;
			this.$v.$touch();

			if (this.$v.$invalid) {
				return;
			}

			let loader = this.$loading.show();

			if(!this.integration._id){

				this.integration.type == this.magentoEnabled ? 'magento' : 'custom';

				this.integration.project = this.project._id;
				this.createIntegration(this.integration).then(()=>{
					this.$notify({ type: 'success', text: 'Se ha creado la integración correctamente',title:  'Integración' });
					this.integrationModal = false;
					this.loadIntegrations();
				}).catch(()=>{
					this.$notify({ type: 'error', text: 'Se produjo un error al crear la integración',title:  'Integración' });
				}).finally(()=>{
					loader.hide();
				})
			}else{
				this.integration.type == this.magentoEnabled ? 'magento' : 'custom';
				this.updateIntegration(this.integration).then(()=>{
					this.$notify({ type: 'success', text: 'Se ha actualizado la integración correctamente',title:  'Integración' });
					this.integrationModal = false;
					this.loadIntegrations();
				}).catch(()=>{
					this.$notify({ type: 'error', text: 'Se produjo un error al actualizar la integración',title:  'Integración' });
				}).finally(()=>{
					loader.hide();
				})
			}
		},

		onCancelIntegrationClicked(){
			this.loadIntegrations();
			this.submitted = false;
			this.integrationModal = false;
		}
    },
	mounted() {
		this.loadIntegrations();
	},
	props: {
		project : {
			type: Object
		}
	},
	emits: []
};
</script>

<template>
	<div class="row">
        <div>
            <div class="row no-gutters">
				<div class="col-xl-3 col-sm-6">
					<div class="card">
						<div class="card-body">
							<a class="dropdown-icon-item" href="javascript: void(0);">
								<img src="https://www.citypng.com/public/uploads/preview/magento-square-logo-icon-png-116633729336xwpmyr00a.png" alt="magento" v-on:click="onIntegrationClicked('magento')" />
								<span class="mb-2">{{'Magento'}}</span>
								<switches v-model="magentoEnabled" type-bold="false" color="primary" class="ml-1 mb-0" emit-on-mount="false"></switches>
							</a>
							<div  v-on:click="onIntegrationClicked('magento')" class="interact"><i class= 'bx bx-info-circle interact'></i> Configurar</div>
						</div>
					</div>
				</div>
				<div class="col-xl-3 col-sm-6">
					<div class="card">
						<div class="card-body">
							<a class="dropdown-icon-item" href="javascript: void(0);">
								<img src="@/assets/images/custom.png" alt="goshops" />
								<span class="mb-2">{{'Custom'}}</span>
								<switches v-model="customEnabled" type-bold="false" color="primary" class="ml-1 mb-0" emit-on-mount="false"></switches>
							</a>
						</div>
					</div>
				</div>
				<div class="col">
					
				</div>
				<div class="col">
				</div>
				<div class="col">
					
				</div>
			</div>
		</div>
		<b-modal v-model="integrationModal" id="modal-center" hide-footer :title="integrationTitle" title-class="font-18">
			<div class="row">
				<div class="col-12">
					<div class="mb-3">
					<label for="url">URL *</label>
					<input
						id="url"
						v-model="integration.data.url"
						type="text"
						class="form-control"
						placeholder="Ingresa la URL"
						:class="{ 'is-invalid': submitted && $v.integration.data.url.$error }"
						
					/>
					<div v-if="submitted && !$v.integration.data.url.required" class="invalid-feedback">
						La URL es requerida para configurar la integración.
					</div>
					</div>
				</div>
				<div class="col-12">
					<div class="mb-3">
						<label for="token">Token *</label>
						<input
							id="token"
							v-model="integration.data.token"
							type="password"
							class="form-control"
							placeholder="Ingresa el token"
							:class="{ 'is-invalid': submitted && $v.integration.data.token.$error }"
						/>
						<div v-if="submitted && !$v.integration.data.token.required" class="invalid-feedback">
							El token es requerido para configurar la integración.
						</div>
					</div>
				</div>
				<div class="text-end pt-5 mt-3">
					<b-button variant="light" @click="onCancelIntegrationClicked">{{$t('common.cancel')}}</b-button>
					<b-button variant="primary" class="ms-1" @click="onConfirmIntegrationClicked">{{$t('common.confirm')}}</b-button>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<style scoped>
.interact {
  cursor: pointer;
}
</style>