<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Integrations from "@/components/projects/integrations2";
import Items from "@/components/projects/items";
import { required } from "vuelidate/lib/validators";

import {
	projectMethods,
  userMethods
} from "@/state/helpers";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "New Project",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Integrations,
    Items
  },
  data() {
    return {
      title: "",
      items: [],
      showModalHost: false,
      showModalProducts:false,
      isLoading: false,
      fullPage: true,
      canCancel: false,
      useSlot: false,
      color: "#007bff",
      bgColor: "#ffffff",
      height: 128,
      width: 128,
      project:{},
      submitted:false,
      itemsCount:0,
      customersCount:0,
      interactionsCount:0,
      apikey:'****************',

    }
  },
  validations: {
    project: {
      name: {
        required,
      }
    },
  },
  mounted() {
      const projectId = this.$route.query.id;
      if(projectId)
        this.loadProject(projectId)
  },
  created() {
    
  },
  methods: {
    ...projectMethods,
    ...userMethods,

    copyValue(value){
            if(value=='project')
                navigator.clipboard.writeText(this.project._id);
            else{

                let loader = this.$loading.show();

                const currentUser = JSON.parse(localStorage.getItem("auth.currentUser"));

                this.getUser(currentUser.user._id).then((user)=>{
                    this.$notify({ type: 'success', text: 'Se ha copiado el api key al portapapeles',title:  'Items' });
                    navigator.clipboard.writeText(user.data.apiKey);
                }).catch(()=>{
                    this.$notify({ type: 'error', text: 'Se produjo un error al obtener el api key',title:  'Items' });
                }).finally(()=>{
                    loader.hide();
                })
                    
                
            }
                
        },

    loadprojectData(){

      if(this.project && this.project._id){
        const params={
					q: `where[project]=${this.project._id}`,
				}

        this.getItems(params).then((res)=>{
          this.itemsCount = res.totalCount;
          }).catch(()=>{
            this.itemsCount = '-';
          })

        this.getCustomers(params).then((res)=>{
          this.customersCount = res.totalCount;
          }).catch(()=>{
            this.customersCount = '-';
          })

        this.getInteractions(params).then((res)=>{
          this.interactionsCount = res.totalCount;
          }).catch(()=>{
            this.interactionsCount = '-';
          })
      }
    },

    loadProject(id){
      let loader = this.$loading.show();

      this.getProject(id).then((res)=>{
        this.project = res.data;
        this.loadprojectData();
      }).catch(()=>{

      }).finally(()=>{
        loader.hide()
      })
    },
    onCancelCreateOrEditProjectClicked() {
      this.$router.push(
              {
                path: "/projects",
              }
            ).catch(() => {});
    },

    onConfirmCreateOrEditProjectClicked(){
      const projectId = this.$route.query.id;

      if(projectId){
        this.onConfirmEditProjectClicked()
      }
      else{
        this.onConfirmCreateProjectClicked();
      }
    },

    onConfirmCreateProjectClicked(){
        
    },

    onConfirmEditProjectClicked(){
      
    },
    onAppClicked(app){
      if(app=='search'){
        this.$router.push(
            {
              path: `/search-project?id=${this.project._id}`,
            }
          ).catch(() => {});
      }else if(app=='reco'){
        this.$router.push(
            {
              path: `/reco-project?id=${this.project._id}`,
            }
          ).catch(() => {});
      
      }else{
        this.$router.push(
            {
              path: `/campaigns?id=${this.project._id}`,
            }
          ).catch(() => {});
      }
    },
    onIntegrationDocClicked(){
      window.open("https://docs.goshops.com/api-reference/interacciones")
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('projects.update_project')" :items="items" />
    <div class="checkout-tabs">
      <b-tabs
        pills
        vertical
        nav-class="p-0"
        nav-wrapper-class="col-xl-2 col-sm-3">
        <b-tab active>
          <template v-slot:title>
            <i class="bx bxs-box d-block check-nav-icon mt-4 mb-2"></i>
            <p class="fw-bold mb-4">{{$t('projects.project_info_title')}}</p>
          </template>
          <b-card-text>
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">{{this.$t('projects.project_info_title')}}</h4>
                <p class="card-title-desc">{{this.$t('projects.project_info_subtitle')}}</p>
                <div class="row">
                  <div class="col-6">
                    <div class="mb-3">
                      <label for="projectName">{{this.$t('projects.new_project_name')}}*</label>
                      <input
                        id="projectName"
                        v-model="project.name"
                        name="name"
                        type="text"
                        class="form-control"
                        :class="{
                          'is-invalid': submitted && $v.project.name.$error,
                        }"
                      />
                      <div
                        v-if="submitted && !$v.project.name.required"
                        class="invalid-feedback">
                          {{this.$t('projects.project_name_required')}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-card-text>
        </b-tab>
        <b-tab v-if="project && project._id">
          <template v-slot:title>
            <i class="bx bxs-customize d-block check-nav-icon mt-4 mb-2"></i>
            <p class="fw-bold mb-4">Integraciones</p>
          </template>
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Integraciones</h4>
              <p class="card-title-desc">Aquí podrás configurar las integraciones para este proyecto.</p>
            </div>
          </div>
          <Integrations :project="project" />
        </b-tab>
        <b-tab v-if="project && project._id">
          <template v-slot:title>
            <i class="bx bxs-data d-block check-nav-icon mt-4 mb-2"></i>
            <p class="fw-bold mb-4">{{$t('projects.project_catalog_title')}}</p>
          </template>
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">{{this.$t('projects.project_catalog_title')}}</h4>
              <p class="card-title-desc">{{this.$t('projects.project_catalog_subtitle')}}</p>

              <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
                <b-tab active>
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      
                    </span>
                    <span class="d-none d-sm-inline-block"> <i class="bx bxs-purchase-tag-alt"></i> Items ({{itemsCount}})</span>
                  </template>
                  <Items :project="project"></Items>
                </b-tab>

                <b-tab>
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-home"></i>
                    </span>
                    <span class="d-none d-sm-inline-block"> <i class="bx bxs-bolt-circle"></i> {{$t('projects.catalog_interactions')}} ({{interactionsCount}})</span>
                  </template>

                  <div class="row mt-4" >
                        <p class="text-muted text-center"> {{$t('projects.add_interactions_api_label')}}
                          <br>
                            <a v-on:click="onIntegrationDocClicked()" class="mt-0 mt-2 interact"> <i class= 'bx bx-code'></i> API Reference</a>
                        </p>

                        <div class="row justify-content-center">
                            <div class="col-6">
                                <div class="mb-3">
                                    <label>x-api-key:</label>
                                    <div class="d-flex">
                                        <input
                                            v-model="apikey"
                                            type="password"
                                            class="form-control"
                                            disabled
                                        />
                                        <i :class="`font-size-17 bx bx-copy me-3 ml-2 interact`" v-on:click="copyValue('apikey')" style="align-self: center; margin-left: 5px;"></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row justify-content-center" >
                            <div class="col-6">
                                <div class="mb-3">
                                    <label>Proyecto:</label>
                                    <div class="d-flex">
                                        <input
                                        id="projectid"
                                        v-model="project._id"
                                        type="text"
                                        class="form-control"
                                        disabled
                                        />
                                        <i :class="`font-size-17 bx bx-copy me-3 ml-2 interact`" v-on:click="copyValue('project')" style="align-self: center; margin-left: 5px;"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-tab>

                <b-tab>
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-home"></i>
                    </span>
                    <span class="d-none d-sm-inline-block"> <i class="bx bxs-user-detail"></i> {{$t('projects.catalog_customers')}}</span>
                  </template>
                  <div class="row mt-4 text-center justify-content-center">
                    {{ $t('projects.add_customers_label') }}
                  </div>
                  
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </b-tab>
        <b-tab v-if="project && project._id">
          <template v-slot:title>
            <i class="bx bxs-magic-wand d-block check-nav-icon mt-4 mb-2"></i>
            <p class="fw-bold mb-4">{{$t('projects.project_apps_title')}}</p>
          </template>
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">{{this.$t('projects.project_apps_title')}}</h4>
              <p class="card-title-desc">{{this.$t('projects.project_apps_subtitle')}}</p>
              <div class="row">
                <div class="col-4">
                <b-card header-class="bg-transparent border-primary" class="border border-primary interaction" :img-src="require('@/assets/images/go-search.svg')" img-alt="Card image" img-top v-on:click="onAppClicked('search')">
                    <b-card-text>
                      {{this.$t('projects.project_apps_go_search')}}
                    </b-card-text>
                </b-card>
              </div>
              <div class="col-4">
                <b-card header-class="bg-transparent border-primary" class="border border-primary interaction " :img-src="require('@/assets/images/go-reco.svg')" img-alt="Card image" img-top v-on:click="onAppClicked('reco')">
                    <p class="card-text">
                        {{this.$t('projects.project_apps_go_reco')}}
                    </p>
                </b-card>
              </div>
              <div class="col-4">
                <b-card header-class="bg-transparent border-primary" class="border border-primary interaction" :img-src="require('@/assets/images/go-live-shop.svg')" img-alt="Card image" img-top v-on:click="onAppClicked('live')">
                    <p class="card-text">
                        {{this.$t('projects.project_apps_go_live')}} 
                    </p>
                </b-card>
              </div>
              </div>
              
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <div class="row mt-4">
          <div class="col-sm-6">
          </div>
          <!-- end col -->
          <div class="col-sm-6">
            <div class="text-end">
              <b-button variant="light" class="w-md mb-3" @click="onCancelCreateOrEditProjectClicked">{{$t('common.cancel')}}</b-button>
              <b-button variant="primary" class="w-md ms-3 mb-3" @click="onConfirmCreateOrEditProjectClicked">{{$t('common.confirm')}}</b-button>
            </div>
          </div>
          <!-- end col -->
        </div>
  </Layout>
</template>

<style scoped>
.card-img-top{
  padding: 30px 40px 5px 40px !important;
}

.interaction {
  cursor: pointer;
}

.interact{
    cursor: pointer;
}
</style>